<template>
    <div class="min-h-screen flex flex-col">
        <header class="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="w-full flex justify-end items-center md:order-2">
                <MetaMaskConnect></MetaMaskConnect>
            </div>
            <div class="w-full flex items-center md:order-1">
                <img class="w-full md:w-60" src="/img/logo.png">
            </div>
        </header>

        <main class="flex-grow px-4 pt-12 flex justify-center items-start md:items-center font-body">
            <router-view/>
        </main>

        <footer class="w-full p-4 text-[10px] text-white/20 flex justify-end items-center select-none">
            v{{ gitDescribe ?? '2.0.0' }} <span class="mx-2 text-white/20">&bull;</span> <a class="pointer hover:underline" href="https://bespoke-bots.xyz/" target="_blank">bespoke-bots.xyz</a>
        </footer>

        <!--        <div class="p-2 fixed left-2 top-2 ring ring-1 ring-gray-400 rounded bg-gray-300 text-pink-600 text-sm z-[60]">-->
        <!--            <span class="text-gray-700 sm:hidden md:hidden lg:hidden xl:hidden font-extrabold">xs</span>-->
        <!--            <span class="text-gray-700 hidden sm:inline md:hidden font-extrabold">sm</span>-->
        <!--            <span class="text-gray-700 hidden md:inline lg:hidden font-extrabold">md</span>-->
        <!--            <span class="text-gray-700 hidden lg:inline xl:hidden font-extrabold">lg</span>-->
        <!--            <span class="text-gray-700 hidden xl:inline font-extrabold">xl</span>-->
        <!--        </div>-->
    </div>
</template>

<script>
import {inject} from 'vue'
import MetaMaskConnect from '@/components/MetaMaskConnect'

export default {
    components: {MetaMaskConnect},

    setup()
    {
        const gitDescribe = inject('gitDescribe')

        return {
            gitDescribe
        }
    }
}
</script>