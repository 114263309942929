import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import {config} from '@/config'
import {ethers} from 'ethers'

export default createStore({
    plugins  : [createPersistedState()],
    state    : {
        metamask          : false,
        network           : null,
        account           : null,
        hasActiveAccount  : false,
        hasVerifiedAccount: false,
        balances          : [],
        bankBalances      : [],
        bankAllowances    : [],
        creating          : false,
        verifying         : false,
        approving         : false,
        depositing        : false,
        withdrawing       : false,
        loadingBalances   : false,
    },
    getters  : {
        metamaskActive    : state => state.metamask,
        correctNetwork    : state => {
            if (state.network === null) {
                state.account = state.balances = null
            }
            return state.network === ethers.utils.hexlify(config.network.chainId)
        },
        connected         : state => !!state.account,
        ready             : state => !!state.account && state.network === ethers.utils.hexlify(config.network.chainId),
        account           : state => state.account,
        hasActiveAccount  : state => state.hasActiveAccount,
        hasVerifiedAccount: state => state.hasVerifiedAccount,
        shortenedAddress  : state => `${state.account.substr(0, 4)}...${state.account.substr(-4, 4)}`,
        balances          : state => state.balances,
        bankBalances      : state => state.bankBalances,
        bankAllowances    : state => state.bankAllowances,
        creating          : state => state.creating,
        verifying         : state => state.verifying,
        approving         : state => state.approving,
        depositing        : state => state.depositing,
        withdrawing       : state => state.withdrawing,
        loadingBalances   : state => state.loadingBalances,
    },
    mutations: {
        setMetamask          : (state, value) => {
            state.metamask = value
        },
        setNetwork           : (state, value) => {
            state.network = value
        },
        setAccount           : (state, value) => {
            state.account = value
        },
        setHasActiveAccount  : (state, value) => {
            state.hasActiveAccount = value
        },
        setHasVerifiedAccount: (state, value) => {
            state.hasVerifiedAccount = value
        },
        setBalances          : (state, value) => {
            state.balances = value
        },
        setBankBalances      : (state, value) => {
            state.bankBalances = value
        },
        setBankAllowances    : (state, value) => {
            state.bankAllowances = value
        },
        setCreating          : (state, value) => {
            state.creating = value
        },
        setVerifying         : (state, value) => {
            state.verifying = value
        },
        setApproving         : (state, value) => {
            state.approving = value
        },
        setDepositing        : (state, value) => {
            state.depositing = value
        },
        setWithdrawing       : (state, value) => {
            state.withdrawing = value
        },
        setLoadingBalances   : (state, value) => {
            state.loadingBalances = value
        },
    },
    actions  : {},
    modules  : {}
})
