import store from '@/store'
import {ethers} from 'ethers'
import {config} from '@/config'
import abi from '@/artifacts/erc-20.json'

export default function useMetaMask() {
    /**
     * Check if MetaMask is available
     */
    async function checkMetaMask()
    {
        store.commit('setMetamask', window.ethereum !== undefined)
    }

    /**
     * Connect to MetaMask account
     */
    async function connectToMetaMask()
    {
        const accounts = await window.ethereum.request({method: 'eth_requestAccounts'})
        store.commit('setAccount', accounts[0])
        store.commit('setNetwork', window.ethereum.chainId)
    }

    /**
     * Connect to the correct chain
     */
    async function connectToCorrectChain()
    {
        const chainId = ethers.utils.hexlify(config.network.chainId)

        window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: chainId}],
        }).then(() => {
            store.commit('setNetwork', chainId)
        })
    }

    /**
     * Sets the account balance for the specified tokens
     */
    async function setBalances()
    {
        if (store.getters.ready) {
            const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
            const signer   = await provider.getSigner()
            const address  = await signer.getAddress()

            let balances = []
            for (const token of config.network.tokens) {
                let balance
                if (token.gas) {
                    balance = ethers.utils.formatEther(await provider.getBalance(address))
                } else {
                    const tokenContract = new ethers.Contract(token.address, abi, provider)
                    const weiBalance    = await tokenContract.balanceOf(address)
                    balance             = ethers.utils.formatEther(weiBalance)
                }

                // let formattedBalance
                // if (balance > 1000) {
                //     formattedBalance = `${(balance / 1000).toFixed(0)}k`
                // } else if (balance > 1000000) {
                //     formattedBalance = `${(balance / 1000000).toFixed(0)}m`
                // } else {
                //     formattedBalance = new Intl.NumberFormat().format(parseFloat(balance))
                // }

                balances.push({
                    name      : token.name,
                    symbol    : token.symbol,
                    icon      : token.icon,
                    balanceRaw: parseFloat(balance),
                    balance   : new Intl.NumberFormat().format(parseFloat(balance))
                })
            }

            store.commit('setBalances', balances)
        }
    }

    /**
     * Connect to the correct chain
     */
    async function disconnect()
    {
        store.commit('setAccount', null)
        store.commit('setBalances', [])
        store.commit('setBankBalances', [])
    }

    /**
     * Watch for changes on the account
     */
    window.ethereum.on('accountsChanged', async function (accounts) {
        store.commit('setAccount', accounts[0])
        await setBalances()
    })

    /**
     * Watch for changes on the chain
     */
    window.ethereum.on('chainChanged', (chainId) => {
        console.log(`on chainChanged: ${chainId}`) // REMOVE
        store.commit('setNetwork', chainId)
        window.location.reload()
    })

    /**
     * Start interval for updating balances
     */
    setInterval(async () => await setBalances(), 5000)

    /**
     * Return to component
     */
    return {
        checkMetaMask,
        connectToMetaMask,
        connectToCorrectChain,
        disconnect,
    }
}