let config

// if (process.env.NODE_ENV === 'production') {
//     config = {}
// } else {
config = {
    network: {
        name        : 'DFK Chain',
        chainId     : 53935,
        explorerUrl : 'https://subnets.avax.network/defi-kingdoms',
        bank_address: '0xcBeC62FdDD92d97E3493407501b89d6491E17429',
        tokens      : [
            {
                name   : 'JEWEL',
                symbol : 'J',
                icon   : 'https://storage.gyd0x.nl/ed2319ff-1320-4572-a9c4-278c4d80b634-bucket/dfk/logo_jewel.png',
                gas    : true,
                address: '0x0000000000000000000000000000000000000000',
            },
            {
                name   : 'CRYSTAL',
                symbol : 'C',
                icon   : 'https://storage.gyd0x.nl/ed2319ff-1320-4572-a9c4-278c4d80b634-bucket/dfk/logo_crystal.png',
                gas    : false,
                address: '0x04b9dA42306B023f3572e106B11D82aAd9D32EBb',
            }
        ]
    }
}
// }

export {config}