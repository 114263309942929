import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path     : '/',
        name     : 'dashboard',
        component: () => import('../views/DashboardView.vue')
    },
    {
        path     : '/create',
        name     : 'create-no-code',
        component: () => import('../views/CreateNoCode.vue')
    },
    {
        path     : '/create/:code',
        name     : 'create',
        component: () => import('../views/CreateAccountView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
