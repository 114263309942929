import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/app.scss'
import 'tw-elements'

const app = createApp(App)

app.provide('gitDescribe', '2.0.0')

app.use(store)
    .use(router)
    .mount('#app')

console.log('%c' + 'Barkeep Kessing', 'font-family: sans-serif; font-size: 50px; font-weight: bold;')
console.log('%c' + `Account dashboard v${app.gitDescribe ?? '2.0.0'}`, 'font-family: sans-serif; font-size: 20px; font-weight: bold;')
console.log('%c' + `Support: https://discord.gg/T6kttxCA69`, 'font-family: sans-serif; font-size: 10px;')
console.log('%c' + `Bug reports: https://discord.gg/2CUcKRzCUj`, 'font-family: sans-serif; font-size: 10px;')
console.log('')
console.log('%c' + 'If someone told you to copy/paste something here you have an 11/10 chance you\'re being scammed!', 'font-family: sans-serif; font-size: 20px; font-weight: bold; color: #FF0000')
console.log('')
console.log('%cBESPOKE%cBOTS\n%chttps://bespoke-bots.xyz/', 'font-family: montserrat, sans-serif; font-size:30px; font-weight: 600', 'font-family: montserrat, sans-serif; font-size:30px; font-weight: 100', 'font-family: montserrat, sans-serif; font-size:12px; font-weight: 100')
