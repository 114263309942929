<template>
    <div class="select-none">
        <div v-if="ready" class="flex items-center">
            <div v-if="loadingBalances" class="mr-2 inline-block h-3 w-3 animate-spin rounded-full border-2 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status"></div>
            <div class="w-fit rounded-full ring ring-1 ring-dfk-green bg-dfk-dark/20 flex items-center text-sm text-white">
                <div class="px-2 py-1 flex items-center">
                    <template v-if="balances.length" v-for="(token, index) in balances">
                        <img class="h-3 mr-1" v-bind:src="token.icon"><span class="text-white">{{ token.balance }}</span> <span v-if="index !== balances.length -1" class="text-dfk-green mx-2">&bull;</span>
                    </template>
                    <template v-else>
                        <div class="inline-block h-3 w-3 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] text-white" role="status"></div>
                    </template>
                </div>
                <div @click="disconnect" class="group w-24 px-2 py-1 rounded-full ring ring-1 ring-dfk-green bg-dfk-green text-center pointer">
                    <span class="group-hover:hidden text-white pointer">{{ shortenedAddress }}</span>
                    <span class="hidden group-hover:block text-white pointer">Disconnect</span>
                </div>
            </div>
        </div>
        <div v-else-if="connected && !correctNetwork">
            <button v-if="!correctNetwork" @click="connectToCorrectChain()" class="w-fit px-2 py-1 rounded-full bg-dfk-green ring ring-1 ring-dfk-green text-sm text-white pointer">Connect to DFK Chain</button>
        </div>
        <div v-else>
            <button @click="connectToMetaMask()" class="w-fit px-2 py-1 rounded-full bg-dfk-green ring ring-1 ring-dfk-green text-sm text-white pointer">Connect</button>
        </div>
    </div>
</template>

<script>
import useMetaMask from '@/mixins/MetaMask'
import {computed} from 'vue'
import {mapGetters, useStore} from 'vuex'

export default {
    setup()
    {
        const store = useStore()
        const {
                  checkMetaMask,
                  connectToMetaMask,
                  connectToCorrectChain,
                  disconnect,
              }     = useMetaMask()

        checkMetaMask()

        return {
            metamaskActive : computed(() => store.getters.metamaskActive),
            correctNetwork : computed(() => store.getters.correctNetwork),
            loadingBalances: computed(() => store.getters.loadingBalances),
            connectToMetaMask,
            connectToCorrectChain,
            disconnect,
        }
    },

    computed: {
        ...mapGetters([
            'connected',
            'ready',
            'shortenedAddress',
            'balances',
        ]),
    }
}
</script>